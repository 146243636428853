import React, { useState, useRef } from "react";
import ReactGA from "react-ga4";
import SpModal from "sp-modal";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import videoSrc from "./images/4335_other-income.mp4";
import picSrc from "./images/other-income-thumbnail.jpeg";
import styles from "./Interview.module.css";

export default function OtherIncome({
  otherIncomeCloseButton,
  handleChange,
  formValues,
}) {
  const [income, setIncome] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };
  const handleClose = () => {
    setModalVisible(false);
  };
  const handleVideoEnd = () => {
    setTimeout(() => {
      setModalVisible(false);
    }, 3000); // Set to 3 seconds after the video ends
  };
  const videoRef = useRef(null);
  const otherIncomeAlert = () => {
    alert(
      "Enter in this step the total of your other estimated income for the year, if any. You shouldn’t include income from any jobs or self-employment."
    );
  };
  const makeChange = (evt) => {
    const { name, value } = evt.target;
    const parsedValue = Math.round(Number(value.replace(/,/g, "")));
    setIncome((prevIncome) => ({
      ...prevIncome,
      [name]: isNaN(parsedValue) ? "" : parsedValue,
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(income);
    ReactGA.event({
      category: "User",
      action: "Completed other income",
    });
    otherIncomeCloseButton();
  };
  return (
    <div className={styles.questionContainer}>
      <div className={styles.headerContainer}>
        <h3>Other Income</h3>
        <img
          onClick={otherIncomeAlert}
          alt="question mark"
          className={styles.question}
          src={questionGraphic}
        />
      </div>
      <div onClick={makeChange}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formContainer}>
            {formValues.processSpouseW4 ? (
              <div className={styles.columnContainer}>
                <div>
                  <p>On Spouse 1's Form W-4</p>
                  <input
                    defaultValue={formValues.otherIncome}
                    name="otherIncome"
                    onChange={makeChange}
                    onFocus={(e) => {
                      e.target.value = "";
                    }}
                  ></input>
                </div>

                <div>
                  <p>On Spouse 2's Form W-4</p>
                  <input
                    defaultValue={formValues.otherIncomeSpouse}
                    name="otherIncomeSpouse"
                    onChange={makeChange}
                    onFocus={(e) => {
                      e.target.value = "";
                    }}
                  ></input>
                </div>
              </div>
            ) : (
              <input
                name="otherIncome"
                defaultValue={formValues.otherIncome}
                onChange={makeChange}
                onFocus={(e) => {
                  e.target.value = "";
                }}
              ></input>
            )}
            <img
              className={styles.thumbnail}
              src={picSrc}
              alt="video thumbnail"
              onClick={openModal}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className={styles.nextButton}>
            <button onClick={handleSubmit}>NEXT</button>
          </div>
        </form>
      </div>
      <SpModal
        visible={modalVisible}
        closeHandler={handleClose}
        className="sp-modal__wrapper sp-modal"
        animation="fade"
      >
        {modalVisible && (
          <video
            ref={videoRef}
            src={videoSrc}
            controls
            autoPlay
            onEnded={handleVideoEnd}
            style={{ width: "100%" }}
          />
        )}
      </SpModal>
    </div>
  );
}
