import React, { useState } from "react";
import ReactGA from "react-ga4";
import SpModal from "sp-modal";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";
import "./modal.css";

export default function PersonalInfo({
  personalInfoCloseButton,
  handleChange,
  formValues,
}) {
  const [personalInfo, setPersonalInfo] = useState({});
  const [modalVisible, setModalVisible] = useState(true);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleModalAutoClose = () => {
    setTimeout(() => {
      setIsFadingOut(true); // Trigger the fade-out effect
      setTimeout(() => {
        setModalVisible(false); // Close the modal after the fade-out
      }, 3000); // Duration matches the CSS transition time
    }, 15000); // Delay before starting the fade-out (15 seconds)
  };

  const personalInfoAlert = () => {
    alert(
      "Make sure the information you enter matches what you use to file your taxes."
    );
  };
  const makeChange = (evt) => {
    const { name, value } = evt.target;

    if (name === "ssn" || name === "spouseSsn") {
      const parsedValue = value.replace(/-/g, "");

      setPersonalInfo({
        ...personalInfo,
        [name]: parsedValue || value,
      });
    } else {
      // For radio button inputs
      setPersonalInfo((personalInfo) => ({
        ...personalInfo,
        [name]: value, // Directly store the selected value
      }));
    }
  };
  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(personalInfo);
    ReactGA.event({
      category: "User",
      action: "Completed personal info",
    });
    personalInfoCloseButton();
  };

  return (
    <>
      {handleModalAutoClose()}
      <form onSubmit={handleSubmit}>
        {formValues.processSpouseW4 ? (
          <div className={styles.questionContainer}>
            <div className={styles.headerContainer}>
              <h3>Personal Info</h3>
              <img
                onClick={personalInfoAlert}
                alt="question mark"
                className={styles.question}
                src={questionGraphic}
              />
            </div>
            <p>
              Entering your personal information is optional here. You can
              always add it to the PDF before or after you print it. We do not
              store your Social Security Number in our database.
            </p>
            <div onClick={makeChange}>
              <div className={styles.stacked}>
                <label>Social Security Number (SSN)</label>
                <input
                  defaultValue={formValues.ssn}
                  name="ssn"
                  onChange={makeChange}
                ></input>
                <label>First name</label>
                <input
                  defaultValue={formValues.firstName}
                  name="firstName"
                  onChange={makeChange}
                ></input>
                <label>Last name</label>
                <input
                  defaultValue={formValues.lastName}
                  name="lastName"
                  onChange={makeChange}
                ></input>
                <label>Street address</label>
                <input
                  defaultValue={formValues.address}
                  name="address"
                  onChange={makeChange}
                ></input>
                <label>City</label>
                <input
                  defaultValue={formValues.city}
                  name="city"
                  onChange={makeChange}
                ></input>
                <label>State (2 digit abbreviation)</label>
                <input
                  defaultValue={formValues.state}
                  name="state"
                  onChange={makeChange}
                ></input>
                <label>Zip code</label>
                <input
                  defaultValue={formValues.zip}
                  name="zip"
                  onChange={makeChange}
                ></input>

                <h3>Spouse 2's Personal Info</h3>
                <div onClick={makeChange}>
                  <div className={styles.stacked}>
                    <label>Spouse Social Security Number (SSN)</label>
                    <input
                      defaultValue={formValues.spouseSsn}
                      name="spouseSsn"
                      onChange={makeChange}
                    ></input>
                    <label>Spouse First name</label>
                    <input
                      defaultValue={formValues.spouseFirstName}
                      name="spouseFirstName"
                      onChange={makeChange}
                    ></input>
                    <label>Spouse Last name</label>
                    <input
                      defaultValue={formValues.spouseLastName}
                      name="spouseLastName"
                      onChange={makeChange}
                    ></input>
                    <label>Street address</label>
                    <input
                      defaultValue={formValues.spouseAddress}
                      name="spouseAddress"
                      onChange={makeChange}
                    ></input>
                    <label>City</label>
                    <input
                      defaultValue={formValues.spouseCity}
                      name="spouseCity"
                      onChange={makeChange}
                    ></input>
                    <label>State (2 digit abbreviation)</label>
                    <input
                      defaultValue={formValues.spouseState}
                      name="spouseState"
                      onChange={makeChange}
                    ></input>
                    <label>Zip code</label>
                    <input
                      defaultValue={formValues.spouseZip}
                      name="spouseZip"
                      onChange={makeChange}
                    ></input>
                  </div>
                  <div className={styles.nextButton}>
                    <button type="submit">NEXT</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.questionContainer}>
              <div className={styles.headerContainer}>
                <h3>Personal Info</h3>
                <img
                  onClick={personalInfoAlert}
                  alt="question mark"
                  className={styles.question}
                  src={questionGraphic}
                />
              </div>
              <p>
                Entering your personal information is optional here. You can
                always add it to the PDF before or after you print it. We do not
                store your Social Security Number in our database.
              </p>
              <div onClick={makeChange}>
                <div className={styles.stacked}>
                  <label>Social Security Number (SSN)</label>
                  <input
                    defaultValue={formValues.ssn}
                    name="ssn"
                    onChange={makeChange}
                  ></input>
                  <label>First name</label>
                  <input
                    name="firstName"
                    onChange={makeChange}
                    defaultValue={formValues.firstName}
                  ></input>
                  <label>Last name</label>
                  <input
                    name="lastName"
                    defaultValue={formValues.lastName}
                    onChange={makeChange}
                  ></input>
                  <label>Street address</label>
                  <input
                    name="address"
                    defaultValue={formValues.address}
                    onChange={makeChange}
                  ></input>
                  <label>City</label>
                  <input
                    name="city"
                    defaultValue={formValues.city}
                    onChange={makeChange}
                  ></input>
                  <label>State (2 digit abbreviation)</label>
                  <input
                    name="state"
                    defaultValue={formValues.state}
                    onChange={makeChange}
                  ></input>
                  <label>Zip code</label>
                  <input
                    name="zip"
                    defaultValue={formValues.zip}
                    onChange={makeChange}
                  ></input>
                </div>
                <div className={styles.nextButton}>
                  <button type="submit">NEXT</button>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
      <SpModal
        visible={modalVisible}
        closeHandler={handleClose}
        className={`sp-modal__wrapper sp-modal ${
          isFadingOut ? "modal-hidden" : ""
        }`} // Use modal-hidden class for fade-out
        animation="fade"
      >
        <div>
          <p>
            Entering your personal information on this screen is optional. We
            promise to:
          </p>
          <ul>
            <li>Never sell your personal information</li>
            <li>Never store your personal information</li>
            <li>Always fully protect your private information</li>
          </ul>
          <p>
            If you don't enter your information here, be sure to enter it on
            your Form(s) W-4 before submitting them to your employer. The PDFs
            are editable before you print them, or you can write the information
            in after you print.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={handleClose}>
            <span style={{ fontSize: "20px" }}>I understand</span>
          </button>
        </div>
      </SpModal>
    </>
  );
}
