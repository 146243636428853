import React, { useRef } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import videoSrc from "./images/4335_home.mp4";
import headerPic from "./images/header-pic.jpg";
import Footer from "./Footer.js";

import Header from "./Header.js";

import styles from "./Home.module.css";
import "./modal.css";

export default function Home() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    // Fire an event when the button is clicked
    ReactGA.event({
      category: "User",
      action: "Clicked Navigate to Interview",
      label: "Interview Page",
    });
    navigate("/interview");
  };
  const videoRef = useRef(null);
  return (
    <div id="page-container">
      <div className={styles.homeContainer}>
        <Header />
        <div className={styles.heroContent}>
          <img src={headerPic} alt="hero-pic" className={styles.heroPic} />
          <div className={styles.heroRow}>
            <h1>Simplify Your W-4 Filing Process</h1>
            <div className={styles.cta2}>
              <button onClick={handleButtonClick}>GET STARTED</button>
            </div>

            <p>
              <b>Frustrated with completing your W-4?</b>
              <br />
              W4married.com is here to help! Our easy-to-use tool asks a few
              simple questions and takes care of the calculations for you. Once
              done, you can print or email your completed form in just a few
              clicks.{" "}
            </p>
          </div>
        </div>
        <div className={styles.heroContent}>
          <div className={styles.whatYouGet}>
            <h2>What you get:</h2>
            <ul>
              <li>
                <b>Guided Prompts</b>: Follow our straightforward questions to
                gather the necessary information.
              </li>
              <li>
                <b>Custom W-4 Forms</b>: Receive a completed W-4 for yourself,
                or both spouses, ready to email or save.
              </li>
              <li>
                <b>Flexible Options</b>: Easily allocate dependents, credits,
                and deductions between your W-4 forms.
              </li>
              <li>
                <b>Privacy First</b>: We never store your personal information.
              </li>
            </ul>
          </div>
          <div className={styles.heroVideo}>
            <h2>Why do I need two W-4s?</h2>
            <video
              ref={videoRef}
              src={videoSrc}
              controls
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className={styles.cta}>
          <p>All for just $2.99!</p>
          <button onClick={handleButtonClick}>GET STARTED</button>
        </div>
      </div>
      <div></div>

      <Footer />
    </div>
  );
}
