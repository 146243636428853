import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import styles from "./Interview.module.css";
import edit from "./images/icons8-edit-96.png";
// import Footer from "./Footer.js";
import FillForm from "./SpousePdfFill.js";
import SpouseFillForm from "./PdfFill.js";
import AccountantReport from "./AccountantReport.js";
import Children from "./Children";
import FilingStatus from "./FilingStatus";
import DeductionType from "./DeductionType";
import OtherIncome from "./OtherIncome";
import OtherDependents from "./OtherDependents";
import PersonalInfo from "./PersonalInfo";
import OtherCredits from "./OtherCredits";
import Income from "./Income";
import ExtraWithholding from "./ExtraWithholding.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import OrderDetails from "./OrderDetails";
import PaymentModal from "./PaymentModal";
import Header from "./Header.js";
import { marriedCalculation } from "./tableLookup/marriedTable";
import { householdCalculation } from "./tableLookup/householdTable";
import { singleCalculation } from "./tableLookup/singleTable";

const initialValues = {
  children: 0,
  childrenSpouse: 0,
  taxFilingStatus: "",
  deductionType: "",
  amountToItemize: 0,
  amountToItemizeSpouse: 0,
  totalAmountToItemize: 0,
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  spouseFirstName: "",
  spouseLastName: "",
  spouseAddress: "",
  spouseCity: "",
  spouseState: "",
  spouseZip: "",
  otherIncome: 0,
  otherIncomeSpouse: 0,
  otherDependents: 0,
  otherDependentsSpouse: 0,
  income1: 0,
  income2: 0,
  credits: 0,
  creditsSpouse: 0,
  processSpouseW4: false,
  paycheckFrequency: "",
  paycheckFrequencySpouse: "",
  withholdingAmount: 0,
  withholdingPrimary: 0,
  withholdingSpouse: 0,
  withholdingPrimaryRecommended: 0,
  withholdingSpouseRecommended: 0,
  ssn: "",
  spouseSsn: "",
  addAccountantForm: false,
};

export default function Interview() {
  const [formInput, setFormInput] = useState(initialValues);
  const [income, setIncome] = useState(false);
  const [childrenOpen, setChildrenOpen] = useState(false);
  const [filingStatus, setFilingStatus] = useState(true);
  const [deductionType, setDeductionType] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(false);
  const [otherIncome, setOtherIncome] = useState(false);
  const [otherDependents, setOtherDependents] = useState(false);
  const [otherCredits, setOtherCredits] = useState(false);
  const [extraWithholding, setExtraWithholding] = useState(false);
  const [orderDetails, setOrderDetails] = useState(false);
  const [showForms, setShowForms] = useState(false);
  const [updatedFormInput, setUpdatedFormInput] = useState(formInput);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [showReviewCompleted, setShowReviewCompleted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(2.99);

  useEffect(() => {
    // If paymentStatus is already "succeeded", skip the modal
    if (paymentStatus === "succeeded") {
      console.log("User has already paid. Skipping payment modal.");
    }
  }, [paymentStatus]);

  const handlePaymentSuccess = (status) => {
    setPaymentStatus(status); // Update the status when payment succeeds
    // Reset the review clicks after a successful payment
    localStorage.setItem("reviewClicksPaid", 0);
    localStorage.setItem("reviewClicksPromo", 0);

    setPaymentStatus("succeeded"); // Update payment status
    setShowForms(true); // Allow them to review the forms again
  };
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);

  const INITIALVALUES = formInput || initialValues;
  function calculateOrderTotal(formValues) {
    if (formValues.processSpouseW4 && formValues.addAccountantForm) return 6.99;
    if (formValues.processSpouseW4 || formValues.addAccountantForm) return 4.99;
    return 2.99;
  }

  const onApplyPromoCode = (appliedPromoCode, appliedDiscount) => {
    setPromoCode(appliedPromoCode); // Set the promo code in state
    setDiscount(appliedDiscount); // Set the discount in state
    console.log(
      `Promo code applied: ${appliedPromoCode}, Discount: ${appliedDiscount}%`
    );
  };
  const onUpdateTotal = (newTotal) => {
    setTotalPrice(newTotal); // Update the total price in state
    if (newTotal === 0) {
      setShowReviewCompleted(true); // Show the "REVIEW COMPLETED FORMS" button when total is $0
    } else {
      setShowReviewCompleted(false); // Show the "FINISH & PAY" button if total > $0
    }
  };
  const showReviewButton = () => {
    setShowReviewCompleted(true); // Set the button to visible
  };
  const handleOpenPaymentModal = async () => {
    if (paymentStatus === "succeeded") {
      return; // Don't open the modal if the user has already paid
    }

    // Calculate the order total (same logic as before)
    const orderTotal = calculateOrderTotal(formInput);

    try {
      // Fetch the clientSecret from the backend
      const response = await fetch(
        "https://stripe-backend-jtzm.onrender.com/create-payment-intent",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: orderTotal }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setClientSecret(data.clientSecret); // Store the clientSecret in state
        setIsPaymentModalOpen(true); // Open the payment modal
      } else {
        console.error("Error fetching client secret:", data.error);
      }
    } catch (error) {
      console.error("Error during fetch:", error.message);
    }

    setIsPaymentModalOpen(true);
  };
  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };
  const statusCloseButton = () => {
    setFilingStatus(false);
    setIncome(true);
  };

  const incomeCloseButton = () => {
    setIncome(false);
    setChildrenOpen(true);
  };

  const childrenCloseButton = () => {
    setChildrenOpen(false);
    setOtherDependents(true);
  };

  const otherDependentsCloseButton = () => {
    setOtherDependents(false);
    setDeductionType(true);
  };

  const deductionCloseButton = () => {
    setDeductionType(false);
    setPersonalInfo(true);
  };

  const personalInfoCloseButton = () => {
    setPersonalInfo(false);
    setOtherIncome(true);
  };
  const incomePercentage = () => {
    return (
      Number(formInput.income1) /
      (Number(formInput.income1) + Number(formInput.income2))
    );
  };
  const spouseIncomePercentage = () => {
    return (
      Number(formInput.income2) /
      (Number(formInput.income1) + Number(formInput.income2))
    );
  };

  const paycheckFrequencyNumber = () => {
    switch (formInput.paycheckFrequency) {
      case "weekly":
        return 52;

      case "monthly":
        return 12;

      case "biweekly":
        return 26;

      case "semi-monthly":
        return 24;

      default:
        return 1;
    }
  };
  const spousePayFrequencyNumber = () => {
    switch (formInput.paycheckFrequencySpouse) {
      case "weekly":
        return 52;

      case "monthly":
        return 12;

      case "biweekly":
        return 26;

      case "semi-monthly":
        return 24;

      default:
        return 1;
    }
  };
  const findExtraWithholding = (income1, income2) => {
    if (income1 > 0 && income2 > 0) {
      let newWithholdingAmount;
      if (formInput.taxFilingStatus === "married") {
        newWithholdingAmount = marriedCalculation(income1, income2);
      } else if (formInput.taxFilingStatus === "household") {
        newWithholdingAmount = householdCalculation(income1, income2);
      } else {
        newWithholdingAmount = singleCalculation(income1, income2);
      }
      setFormInput((prevState) => ({
        ...prevState,
        withholdingAmount: newWithholdingAmount,
      }));
    } else {
      setFormInput((prevState) => ({
        ...prevState,
        withholdingAmount: 0,
      }));
    }
  };
  const primaryPerPaycheck = () => {
    let newPrimaryRecommended = 0;
    newPrimaryRecommended =
      (formInput.withholdingAmount /
        paycheckFrequencyNumber(formInput.paycheckFrequency)) *
      incomePercentage();
    setFormInput((prevState) => ({
      ...prevState,
      withholdingPrimaryRecommended: newPrimaryRecommended.toFixed(),
    }));
  };

  const spousePerPaycheck = () => {
    let newSpouseRecommended = 0;
    newSpouseRecommended =
      (formInput.withholdingAmount /
        spousePayFrequencyNumber(formInput.spousePayFrequencyNumber)) *
      spouseIncomePercentage();
    setFormInput((prevState) => ({
      ...prevState,
      withholdingSpouseRecommended: newSpouseRecommended.toFixed(),
    }));
  };

  const otherIncomeCloseButton = () => {
    setOtherIncome(false);
    findExtraWithholding(formInput.income1, formInput.income2);
    setOtherCredits(true);
  };

  const otherCreditsCloseButton = () => {
    setOtherCredits(false);

    primaryPerPaycheck();
    spousePerPaycheck();

    setExtraWithholding(true);
  };

  const extraWithholdingCloseButton = () => {
    setExtraWithholding(false);
    if (paymentStatus !== "succeeded") {
      // Open the payment modal if payment hasn't succeeded yet
      setOrderDetails(true); // This could be your payment modal trigger
    } else {
      // Skip payment modal if payment has already succeeded
      console.log("Payment already succeeded, skipping payment process.");
    }
  };

  const orderDetailsCloseButton = () => {
    setOrderDetails(false);
    handleOpenPaymentModal();
  };
  const handleChange = (updatedInput) => {
    setFormInput({
      ...formInput,
      ...updatedInput,
    });
  };
  const handleReviewButtonClick = () => {
    if (paymentStatus === "succeeded") {
      let reviewClicksPaid = localStorage.getItem("reviewClicksPaid") || 0;

      // Check if they’ve exceeded the limit
      if (reviewClicksPaid >= 5) {
        alert("You have reached the maximum number of reviews after payment.");
        return false; // Return false, user can't see forms
      }

      // Increment clicks for paid reviews
      reviewClicksPaid++;
      localStorage.setItem("reviewClicksPaid", reviewClicksPaid);
    } else if (showReviewCompleted) {
      let reviewClicksPromo = localStorage.getItem("reviewClicksPromo") || 0;

      // Check if they’ve exceeded the limit
      if (reviewClicksPromo >= 5) {
        alert("You have used up all your promo codes. Please pay to continue.");
        handleOpenPaymentModal(); // Open the payment modal for a new payment
        return false; // Return false, user can't see forms
      }

      // Increment clicks for promo code reviews
      reviewClicksPromo++;
      localStorage.setItem("reviewClicksPromo", reviewClicksPromo);
    }

    return true; // Return true if user is allowed to see the forms
  };

  const handleShowFormsClick = () => {
    // Call handleReviewButtonClick to check if they can proceed
    const canShowForms = handleReviewButtonClick();

    // Only show the forms if they are allowed to see them
    if (canShowForms) {
      setUpdatedFormInput(formInput);
      setShowForms(true); // Show forms only if limit hasn't been exceeded
      setOrderDetails(false);
      ReactGA.event({
        category: "User",
        action: "Hit Review Completed Forms button",
        label: "Successful payment",
      });
    }
  };

  const renderForms = () => {
    if (
      updatedFormInput.processSpouseW4 &&
      updatedFormInput.addAccountantForm
    ) {
      return (
        <>
          <SpouseFillForm formInput={updatedFormInput} />
          <FillForm formInput={updatedFormInput} />
          <AccountantReport formInput={updatedFormInput} />
        </>
      );
    } else if (
      !updatedFormInput.processSpouseW4 &&
      updatedFormInput.addAccountantForm
    ) {
      return (
        <>
          <FillForm formInput={updatedFormInput} />
          <AccountantReport formInput={updatedFormInput} />
        </>
      );
    } else if (
      updatedFormInput.processSpouseW4 &&
      !updatedFormInput.addAccountantForm
    ) {
      return (
        <>
          <SpouseFillForm formInput={updatedFormInput} />
          <FillForm formInput={updatedFormInput} />
        </>
      );
    } else {
      return <FillForm formInput={updatedFormInput} />;
    }
  };
  const handleReset = () => {
    setFormInput({
      ...initialValues,
      addAccountantForm: formInput.addAccountantForm, // Preserve this field
      processSpouseW4: formInput.processSpouseW4,
    });
    setFilingStatus(true);
    setShowForms(false);
  };
  console.log("from interview", formInput);
  return (
    <div id="page-container">
      <div className={styles.interviewContainer}>
        <Header />
        {/* start of filing status */}
        {filingStatus ? (
          <FilingStatus
            statusCloseButton={statusCloseButton}
            handleChange={handleChange}
            formInput={formInput}
          />
        ) : (
          <div
            onClick={() => setFilingStatus(true)}
            className={styles.questionClosed}
          >
            <h3>Filing Status</h3>
            <img className={styles.editButton} src={edit} alt="edit" />
          </div>
        )}
        {/* start of income1 and income2 */}
        <div className={filingStatus ? styles.invisible : styles.visible}>
          {income ? (
            <Income
              incomeCloseButton={incomeCloseButton}
              handleChange={handleChange}
              initialValues={INITIALVALUES}
            />
          ) : (
            <div
              onClick={() => setIncome(!income)}
              className={styles.questionClosed}
            >
              <h3>Income</h3>
              <img className={styles.editButton} src={edit} alt="edit" />
            </div>
          )}
          {/* start of number of children */}
          <div className={income ? styles.invisible : styles.visible}>
            {childrenOpen ? (
              <Children
                childrenCloseButton={childrenCloseButton}
                handleChange={handleChange}
                initialValues={INITIALVALUES}
              />
            ) : (
              <div
                onClick={() => setChildrenOpen(!childrenOpen)}
                className={styles.questionClosed}
              >
                <h3>Children</h3>
                <img className={styles.editButton} src={edit} alt="edit" />
              </div>
            )}
            {/* beginning of other dependents */}
            <div className={childrenOpen ? styles.invisible : styles.visible}>
              {otherDependents ? (
                <OtherDependents
                  otherDependentsCloseButton={otherDependentsCloseButton}
                  handleChange={handleChange}
                  formValues={formInput}
                />
              ) : (
                <div
                  onClick={() => setOtherDependents(true)}
                  className={styles.questionClosed}
                >
                  <h3>Other Dependents</h3>
                  <img className={styles.editButton} src={edit} alt="edit" />
                </div>
              )}

              {/* beginning of deduction type */}
              <div
                className={otherDependents ? styles.invisible : styles.visible}
              >
                {deductionType ? (
                  <DeductionType
                    deductionCloseButton={deductionCloseButton}
                    handleChange={handleChange}
                    formValues={formInput}
                  />
                ) : (
                  <div
                    onClick={() => setDeductionType(true)}
                    className={styles.questionClosed}
                  >
                    <h3>Deduction Type</h3>
                    <img className={styles.editButton} src={edit} alt="edit" />
                  </div>
                )}

                {/* beginning of personal info */}
                <div
                  className={deductionType ? styles.invisible : styles.visible}
                >
                  {personalInfo ? (
                    <PersonalInfo
                      personalInfoCloseButton={personalInfoCloseButton}
                      handleChange={handleChange}
                      formValues={formInput}
                    />
                  ) : (
                    <div
                      onClick={() => setPersonalInfo(true)}
                      className={styles.questionClosed}
                    >
                      <h3>Personal Info</h3>
                      <img
                        className={styles.editButton}
                        src={edit}
                        alt="edit"
                      />
                    </div>
                  )}

                  {/* beginning of other income */}
                  <div
                    className={personalInfo ? styles.invisible : styles.visible}
                  >
                    {otherIncome ? (
                      <OtherIncome
                        otherIncomeCloseButton={otherIncomeCloseButton}
                        handleChange={handleChange}
                        formValues={formInput}
                      />
                    ) : (
                      <div
                        onClick={() => setOtherIncome(true)}
                        className={styles.questionClosed}
                      >
                        <h3>Other Income</h3>
                        <img
                          className={styles.editButton}
                          src={edit}
                          alt="edit"
                        />
                      </div>
                    )}
                    {/* beginning of other credits */}
                    <div
                      className={
                        otherIncome ? styles.invisible : styles.visible
                      }
                    >
                      {otherCredits ? (
                        <OtherCredits
                          otherCreditsCloseButton={otherCreditsCloseButton}
                          handleChange={handleChange}
                          formValues={formInput}
                        />
                      ) : (
                        <div
                          onClick={() => setOtherCredits(true)}
                          className={styles.questionClosed}
                        >
                          <h3>Other Credits</h3>
                          <img
                            className={styles.editButton}
                            src={edit}
                            alt="edit"
                          />
                        </div>
                      )}
                      {/* beginning of extra withholding */}
                      <div
                        className={
                          otherCredits ? styles.invisible : styles.visible
                        }
                      >
                        {extraWithholding ? (
                          <ExtraWithholding
                            extraWithholdingCloseButton={
                              extraWithholdingCloseButton
                            }
                            handleChange={handleChange}
                            formValues={formInput}
                          />
                        ) : (
                          <div
                            onClick={() => setExtraWithholding(true)}
                            className={styles.questionClosed}
                          >
                            <h3>Extra Withholding</h3>
                            <img
                              className={styles.editButton}
                              src={edit}
                              alt="edit"
                            />
                          </div>
                        )}
                        {/* beginning of order details */}
                        <div
                          className={
                            extraWithholding ? styles.invisible : styles.visible
                          }
                        >
                          {orderDetails ? (
                            <OrderDetails
                              orderDetailsCloseButton={orderDetailsCloseButton}
                              handleChange={handleChange}
                              formValues={formInput}
                              onApplyPromoCode={onApplyPromoCode}
                              showReviewButton={showReviewButton}
                              onUpdateTotal={onUpdateTotal}
                            />
                          ) : (
                            <div
                              onClick={() => setOrderDetails(true)}
                              className={styles.questionClosed}
                            >
                              <h3>Order Details</h3>
                              <img
                                className={styles.editButton}
                                src={edit}
                                alt="edit"
                              />
                            </div>
                          )}
                          {paymentStatus === "succeeded" ||
                          showReviewCompleted ? (
                            <div>
                              <h3 className={styles.centeredHeader}>
                                Your PDFs are ready to download and/or print:
                              </h3>
                              {/* Show the REVIEW COMPLETED FORMS button */}
                              <div className={styles.nextButton}>
                                <button
                                  type="submit"
                                  style={{ marginBottom: "10px" }}
                                  onClick={handleShowFormsClick}
                                >
                                  REVIEW COMPLETED FORMS
                                </button>
                                {showForms && renderForms()}
                              </div>
                            </div>
                          ) : (
                            <div className={styles.nextButton}>
                              <button
                                type="submit"
                                onClick={handleOpenPaymentModal}
                              >
                                FINISH & PAY
                              </button>
                            </div>
                          )}

                          <div
                            className={
                              !showForms ? styles.invisible : styles.visible
                            }
                          >
                            <div className={styles.nextButton}>
                              <button type="reset" onClick={handleReset}>
                                RESET FORM
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPaymentModalOpen && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentModal
            onClose={handleClosePaymentModal}
            visible={setIsPaymentModalOpen}
            formValues={formInput}
            onPaymentSuccess={handlePaymentSuccess}
          />
        </Elements>
      )}

      {/* <Footer /> */}
    </div>
  );
}
