import React, { useState } from "react";
import ReactGA from "react-ga4";
import questionGraphic from "./images/question-mark-circle-4-128.png";
import styles from "./Interview.module.css";

export default function OtherDependents({
  otherDependentsCloseButton,
  handleChange,
  formValues,
}) {
  const [dependents, setDependents] = useState();
  const otherDependentsAlert = () => {
    alert(
      "You may be able to claim a credit for other dependents for whom a child tax credit can’t be claimed, such as a child over 17 who is living with you, a full-time college student under 24, or a qualifying relative. For additional eligibility requirements for these credits, see Pub. 501, Dependents, Standard Deduction, and Filing Information."
    );
  };

  const makeChange = (evt) => {
    const { name, value } = evt.target;
    const parsedValue = Math.floor(Number(value));
    setDependents((prevDependents) => ({
      ...prevDependents,
      [name]: parsedValue,
    }));
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    handleChange(dependents);
    ReactGA.event({
      category: "User",
      action: "Completed number of other dependents step",
    });
    otherDependentsCloseButton();
  };
  return (
    <div className={styles.questionContainer}>
      <div className={styles.headerContainer}>
        <h3>Other Dependents</h3>
        <img
          onClick={otherDependentsAlert}
          alt="question mark"
          className={styles.question}
          src={questionGraphic}
        />
      </div>
      <div onClick={makeChange}>
        <form onSubmit={handleSubmit}>
          {formValues.processSpouseW4 ? (
            <div className={styles.container}>
              <div className={styles.row}>
                <p>On Spouse 1's Form W-4</p>
                <input
                  name="otherDependents"
                  defaultValue={formValues.otherDependents}
                  onChange={makeChange}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                ></input>
              </div>
              <div className={styles.row}>
                <p>On Spouse 2's or second W-4</p>
                <input
                  name="otherDependentsSpouse"
                  defaultValue={formValues.otherDependentsSpouse}
                  onChange={makeChange}
                  onFocus={(e) => {
                    e.target.value = "";
                  }}
                ></input>
              </div>
            </div>
          ) : (
            <input
              name="otherDependents"
              defaultValue={formValues.otherDependents}
              onChange={makeChange}
              onFocus={(e) => {
                e.target.value = "";
              }}
            ></input>
          )}
          <div className={styles.nextButton}>
            <button type="submit">NEXT</button>
          </div>
        </form>
      </div>
    </div>
  );
}
